@import "../../../variables.scss";

.risk-card-container {
  gap: 16px;
  display: flex;
  .stats-card-container {
    width: 69%;
    background-color: $surface10;
    border-radius: 4px;
  }
  .card {
    height: 100%;
    border: none;
    background-color: $surface10;
  }
  .main-card {
    min-width: 100px;
  }
  .risk-card-title {
    font-family: $manrope-regular-font;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $surface80;
    border-bottom: none;
    padding: 10px 0 10px;
  }
  .vertical-line {
    margin-left: 15px;
    border-left: 1px solid $card-border-color-3;
    height: 105px;
    background-color: $body-background-color;
  }

  .risk-card-score {
    font-family: $manrope-regular-font;
    font-size: 32px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 5px 0 0px;
    color: $surface80;
  }

  .score-direction {
    float: right;
    font-size: 18px;
  }
  .low-opacity {
    opacity: 0.4;
  }
  .steps-count {
    width: 23px;
    height: 21px;
    margin-right: 8px;
    line-height: 23px;
    border-radius: 50%;
    text-align: center;
    object-fit: contain;
    &-disable {
      @extend .steps-count;
      opacity: 1;
    }
    &-enable {
      @extend .steps-count;
      opacity: 1;
    }
  }

  .stats-icon {
    width: 16px;
    background: unset;
    border-radius: 50%;
    margin-left: 10px;
    path {
      fill: $primaryBlue;
    }
    path:nth-child(2) {
      fill: $white-color;
    }
  }
  .stats-loading-icon {
    width: 18px;
    right: 10px;
    top: 10px;
    border-radius: 50%;
    margin-left: 10px;
  }
  .card {
    width: 100%;
    &-disable {
      opacity: 1;
    }
    &-enable {
      border: solid 1px $grey-color !important;
      background-color: $white-color;
    }
  }
  .discovering-text {
    padding-right: 0px;
    padding-left: 15px;
    border-left: 1px solid $card-border-color-3;
    height: 100%;
    @media (max-width: 992px) {
      border: 0;
    }
    img {
      width: 22px;
    }
    div {
      padding-left: 15px;
      p {
        font-family: $manrope-regular-font;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $subtext-color;
        margin-bottom: 0.5rem;
      }
      button {
        background-color: $score-low-color;
      }
    }
  }
}
