// colors

$subtext-color: #e6e7ed;
$body-background-color: #e2e2e2;
$card-background-color: #191c2e;
$chart-background-color: #ececec;
$modal-background: rgba(0, 0, 0, 0.8);
$tab-border: #2a283d;
$input-background: #1b1f32;
$purple-color: #291f4b;
$surface-0: #080b1e;
$surface-80: #141729;

$table-text: #e7e7e9;
$grey-color: rgb(138, 137, 137);
$white-color: #fff;
$card-border-color: #d3d3d3;
$card-border-color-2: #5f5f5f;
$card-border-color-3: #b7b7b7;
$card-border-color-4: #d7dbda;
$card-heading-color: #fff;
$sub-heading-color: #fff;
$toggle-button-color: #ae69f4;
$sub-heading-color-2: rgba(100, 137, 217, 0.19);
$input-color: #ededed;
$radio-button-color: #8ba2da;
$error-color: #c83939;
$warning-color: #e0a44c;
$success-color: #6cbb6d;
$risk-color: #df9797;
$box-shadow: #0000001c;
$chart-quarter-color: #797979;
$chart-grid-line-color: #c0c0bb;
$chart-text-color: #8f8883;
$sidebar-icon: #9db4e9;
$sidebar-icon-background: #6c758b;
$sidebar-active-background: #444957;
$header-notification-background: #4a4f5a;
$ticket-background: #545968;
$risk-title-accept: #d4a800;
$risk-title-accepted: #d4a800;
$ticket-color: #c8d8ff;
$ticket-tbody: #f0f0f0;
$secondary-btn-hover: #777d74;
$separator-color: #d9d9d9;
$disabled-color: #c6ccd1;
$gainsboro: #d8d8d8;
$toggle-track: rgba(174, 105, 244, 0.1);
$lavenderBlue: #c8d7ff;
$button-primary-color: linear-gradient(
  96.28deg,
  #002bff 2.28%,
  #4377f7 103.97%
);
$border-line: linear-gradient(
    102.8deg,
    #c241af -2.26%,
    #002bff 77.68%,
    #4377f7 117.73%
  )
  3;
$text-primary: #4a61ff;
$wiz-blue: #002bff;
$light-grey: #cdcbcb;

// score colors
// $score-low-color: #6c83bb;
// $score-moderate-color: #fecf1c;
// $score-high-color: #dc6e30;
// $score-elevated-color: #e32b2a;

$score-low-color: #3260e5;
$score-moderate-color: #fecf1c;
$score-high-color: #dc6e30;
$score-elevated-color: #e32b2a;

$score-low-bg-color: #16224d;
$score-moderate-bg-color: #3f3925;
$score-high-bg-color: #382529;
$score-elevated-bg-color: #391828;

$no-data-heading: #bbbbbb;

//new theme colors
$blackcurrant: #141432;
$cyprus: #080c20;
$block-ui-color: #5d74a0;
$modal-header-border: #333647;
$secondary-color: #292b3c;
$violent-violet: #23203f;
$alizarin-bg-red: #e32b2a33;
$alizarin-red: #e32b2a;
$midnight-express: #1d2032;
$midnight-express-blue: #202436;
$midnight-express-1: #161a2d;
$tangaroa: #181d36;
$spun-pearl: #9fa1a9;
$grey-shade: #7f7f7f;
$grey-shade-2: rgba(101, 101, 101, 0.2);
$grey-shade-3: #cfd0d4;
$grey-shade-4: #9c9ea6;
$storm-grey: #6f717d;
$ghost-grey: #cfd0d4;
$linear-blue-bg: linear-gradient(96.28deg, #002bff 2.28%, #4377f7 103.97%);
$jordy-blue: #7c9de3;
$card-heading-bg: rgba(8, 12, 32, 0.6);
$dodger-blue: #0085ff;
$royal-blue: #3260e5;
$box-shadow-shade: rgba(0, 0, 0, 0.5);
$manatee: #858691;
$manatee-shade1: #b5b8ca;
$dark-blue: #3d61ff;
$highlighted-color-card: linear-gradient(
  310.96deg,
  #4d2b9b -8.12%,
  #3818ad 44.12%,
  #4d2b9b 98.54%
);
$fire-brick: #b42120;
$dark-pastel-green: #0baa50;
$onboarding-icons: #26293a;
$chip-background: #24356e;
$chip-font: #3d61ff;
$line-color: #5538b3;
$violent-violet: #392f6d;
$week-btn: #3260e6;
$steeper-border: #373453;
$ghost-grey-shade: #b7b8be;
$christalle: #271f59;
$midnight-express: #1c2138;
$anon: #bcbbc6;
$royal-curtsy: #272c48;
$midnight-express1: #696b77;
$mainly-blue: #1d2137;
$dark-mainly-blue: #1d2137;
$achievement-card-color: #222046;
// font-family
$manrope-bold-font: "Inter", sans-serif;
$manrope-regular-font: "Inter", sans-serif;
$manrope-semibold-font: "Inter", sans-serif;
$accordion-color: #5134df;
$accordion-search-color: #575a68;
$accordion-background: #f8f2ff;

// v1 varibales

$card-background-color-v1: #191c2e;
$table-cell-text-color-v1: rgba(255, 255, 255, 0.7);
$table-background-color-v1: rgba(255, 255, 255, 0.03);
$border-color-v1: rgba(255, 255, 255, 0.07);
$border-color-v1-2: rgba(255, 255, 255, 0.04);
$border-color-v1-3: rgba(0, 0, 0, 0.2);
$border-color-v1-4: rgba(255, 255, 255, 0.1);
$border-color-v1-5: rgba(255, 255, 255, 0.5);
$world-map-tooltip-color: #12122d;
$beta-tag-color: rgba(131, 106, 255, 0.2);
$beta-tag-color-2: #2c2760;
$dashboard-subcard-color: #2b1e68;
$cloud-env-class-color-1: #4260ff;
$cloud-env-class-color-2: #8f21ff;
$cloud-env-class-color-3: #a955ff;
$tooltip-background-color: #262655;
$text-highlight-color: #836aff;
$link-v1: rgba(61, 97, 255, 1);
$table-header: #251d43;
$filter-text-color: #a2a2af;
$access-path-filter: #c68eff;
$live-access-filter: #ac5aff;

// material color codes
// Primary Palette
$lightBlue: #92caff;
$indigo: #8a4ce2;
$lavender: #ab7fea;
$lightPurple: #ccb2f2;

// Data Visualization Palette
$pictonBlue: #ac6aee;
$lightMagenta: #816dff;
$purpleStroke: #6c56f1;
$redStroke: #ed483d;
$peach: #f4beb4;
$paleBlue: #92caff;
$laurelGreen: #accb9e;
$yellowRed: #f5b483;
$green: #96dfd8;
$mistyMoss: #a75e6e;

// Define color variables
$primaryBlue: #2195ff;
$secondaryBlue: #5cb1ff;
$blueGrey: #21253b;
$critical: #ff655b;
$high: #f88d50;
$warning: #fbc355;
$success: #53d7bb;
$surface100: #ffffff;
$surface90: #f5f5f9;
$surface80: #e6e7ed;
$surface70: #ced0dc;
$surface60: #b5b8ca;
$surface50: #787d9e;
$surface40: #63698b;
$surface30: #535874;
$surface20: #3b3e52;
$surface10: #191c2e;
$surface0: #080b1e;
$surfaceMain: #010102;
$surfaceFloatingWindow: #1f2338;
$surfaceCard: #121523;
