@import '../../variables.scss';

#cd-top-header {
  background: $blackcurrant;
  width: 100%;
  height: 4.875rem;
  position: sticky;
  top: 0;
  z-index: 900;

  .header-main-container {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    height: 100%;
    align-items: center;

    .left-container {
      ul {
        display: flex;
        list-style: none;
        margin-bottom: 0px;
        padding-left: 0px;
        li {
          .risk-score-label {
            font-style: normal;
            font-weight: 600;
            font-size: 0.938rem;
            line-height: 1rem;
            letter-spacing: 0.01em;
            color: $white-color;
            opacity: 0.9;
            margin-bottom: 0px;
          }

          .datastore-risk-score-label {
            // background: $alizarin-bg-red;
            border-radius: 0.204rem;
            label {
              font-style: normal;
              font-weight: 600;
              font-size: 0.813rem;
              line-height: 1rem;
            }
          }

          .accounts-label {
            font-style: normal;
            font-weight: 400;
            font-size: 0.938rem;
            line-height: 1.125rem;
            color: $white-color;
            opacity: 0.6;
            margin-bottom: 0px;
          }
          .accounts-dropdown--is-disabled {
            opacity: 0.5;
          }
          .accounts-dropdown {
            cursor: pointer;
            .accounts-dropdown__control {
              background: transparent;
              border: none;
              outline: none;
              box-shadow: none;
              cursor: pointer;
            }
            .accounts-dropdown__control--is-focused {
              outline: none;
            }
            .accounts-dropdown__single-value {
              color: white;
            }
            .accounts-dropdown__indicator {
              padding-left: 0px;
            }
            .accounts-dropdown__indicator-separator {
              display: none !important;
            }
            .accounts-dropdown__menu {
              background: $midnight-express;
              width: 9.375rem;
              border: 1px solid $midnight-express-blue;
              .accounts-dropdown__menu-list {
                width: 100%;
                background: $midnight-express;
                .accounts-dropdown__option {
                  margin-top: 0.125rem;
                  margin-bottom: 0.125rem;
                  &:hover {
                    background: $violent-violet;
                    color: $toggle-button-color;
                  }
                }
                .accounts-dropdown__option {
                  background: none;
                }
                .accounts-dropdown__option--is-selected {
                  background: $violent-violet;
                  color: $toggle-button-color;
                }
              }
            }
          }
        }
      }
    }

    .right-container {
      .user-name {
        font-style: normal;
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 0.938rem;
        color: $table-text;
      }
      .customer-name {
        margin-top: -0.438rem;
        font-weight: 400;
        font-size: 0.625rem;
        line-height: 0.75rem;
        color: $manatee;
      }
    }
  }
}

.expanded-header {
  // padding-left: 11.375rem;
}
.collapsed-header {
  // padding-left: 4.875rem;
}

.profile-popup-content {
  background: $tangaroa;
  border-radius: 0.25rem !important;
  margin-top: 0.938rem !important;

  hr {
    margin: 0.313rem;
    opacity: 0.1;
    border: 0.063rem solid $white-color;
  }

  .popup-item {
    padding: 0.625rem 1.25rem;
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.938rem;
    cursor: pointer;
    color: $spun-pearl;
    &:hover {
      background: $violent-violet;
      color: $toggle-button-color;
    }
  }
}
.settings-popup-content {
  background: $tangaroa;
  border-radius: 0.25rem !important;

  hr {
    margin: 0.313rem;
    opacity: 0.1;
    border: 0.063rem solid $white-color;
  }

  .popup-item {
    padding: 0.625rem 1.25rem;
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.938rem;
    cursor: pointer;
    color: $spun-pearl;
    &:hover {
      background: $violent-violet;
      color: $toggle-button-color;
    }
  }
}

.score-low-level {
  color: $score-low-color !important;
}

.score-moderate-level {
  color: $score-moderate-color !important;
}

.score-high-level {
  color: $score-high-color !important;
}

.score-elevated-level {
  color: $score-elevated-color !important;
}

// BG colors for score
.score-low-bg-level {
  background: $score-low-bg-color !important;
}

.score-moderate-bg-level {
  background: $score-moderate-bg-color !important;
}

.score-high-bg-level {
  background: $score-high-bg-color !important;
}

.score-elevated-bg-level {
  background: $score-elevated-bg-color !important;
}
.toggle-title {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: $toggle-button-color;
  &-disable {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: $manatee-shade1;
  }
}
.risk-accept-toggle.react-toggle--checked .react-toggle-track {
  background: $toggle-track !important;
}
.risk-accept-toggle.react-toggle .react-toggle-track {
  background: $toggle-track !important;
}
.popup-content {
  max-width: 300px;
}
