@import "../../variables.scss";
.calender {
  display: flex;
  margin-left: 9em;
  margin-top: 10px;
  .day {
    border: 1px solid $no-data-heading;
    padding: 2px;
    width: 80px;
    text-align: center;
    color: $surface80;
    background: $white-color;
    // cursor: pointer;
  }
  .day-active {
    color: $white-color;
    background: $surface80;
  }
}
