@import "../../variables.scss";

.pagination {
  display: inline;
  span {
    color: $white-color;
    font-size: 14px;
  }
  .pagination-icon-first {
    margin-right: -8px;
    padding: 0px;
    margin-left: -3px;
  }
  .pagination-icon-last {
    margin-left: -8px;
    padding: 0px;
    margin-right: -3px;
  }
}
