@import "../../../variables.scss";
#risk-accept-modal {
  .modal {
    display: block;
    background: rgba(0, 0, 0, 0.5);
    .modal-dialog {
      max-width: 700px;
      top: 15%;
      .modal-content {
        height: 100% !important;
        background: $card-background-color;
        .modal-loading-img {
          position: absolute;
          margin: auto;
          left: 0;
          right: 0;
          text-align: center;
          z-index: 1;
          width: 200px;
          top: 2rem;
        }
        .modal-header {
          padding: 15px 15px;
          .header-title {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $surface80;
          }
          .risk-modal-title {
            &-accept {
              color: $warning-color;
            }
            &-accepted {
              color: $success-color;
            }
          }

          .delete-icon {
            position: absolute;
            right: 0;
            cursor: pointer;
          }
        }
        .modal-body {
          border-bottom: 1px solid;
          border-bottom-color: $card-border-color;
          .radio-label {
            color: $white-color;
            cursor: pointer;
            .radio-button-text {
              .risk-modal-description {
                margin-left: 3em;
              }
            }
          }
          .risk-modal-description {
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
          }
        }
      }
      .modal-footer {
        .modal-button-cancel {
          background-color: $card-heading-color;
          color: $white-color;
        }
      }
    }
  }
}
