@import "../../../variables.scss";
#integration-modal {
  .modal {
    display: block;
    background: rgba(0, 0, 0, 0.5);
    .modal-dialog {
      max-width: 40rem;
      .modal-content {
        // height: 600px !important;
        background: $card-background-color-v1;

        .modal-header {
          padding: 15px;
          color: $surface80;
          font-size: 18px;
          font-weight: normal;
          border-bottom: 0.5px solid $modal-header-border;
        }
        .modal-body {
          label {
            margin-bottom: 0px;
          }
          border-bottom: solid 1px $card-border-color;
          .modal-loading-img {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            text-align: center;
            z-index: 1;
            width: 200px;
          }

          .email-chip {
            font-family: $manrope-regular-font;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #6c83bb;
            border-radius: 10px;
            padding: 5px;
            border: 1px solid #6c83bb;
            img {
              width: 19px;
              height: 19px;
              opacity: 0.8;
              cursor: pointer;
            }
          }

          .modal-input-style {
            border-width: 0px;
            left: 0px;
            top: 0px;
            width: 100%;
            border-radius: 2px !important;
            height: 35px;
            background: inherit;
            background-color: $input-color;
            box-sizing: border-box;
            border-width: 1px;
            border-style: solid;
            border-color: $card-border-color;
            border-radius: 0px;
            font-family: $manrope-regular-font;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
            box-shadow: none;
          }
        }
        .modal-footer {
          .btn {
            text-transform: uppercase;
          }
          .btn-primary {
            &:active {
              background: $surface80;
              border-color: $surface80;
            }
            &:disabled {
              background: $surface80;
              border-color: $surface80;
              opacity: 0.3;
            }
          }
          .separator {
            width: 1px;
            height: 30px;
            background-color: $separator-color;
          }
        }
      }
    }
  }
}
