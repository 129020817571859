@import "../../../variables.scss";
#update-account {
  .modal {
    display: block;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    overflow: scroll;
    .modal-dialog {
      top: 10%;
      .modal-content {
        height: 100% !important;
        background: $card-background-color;
        padding-right: 40px;
        padding-left: 40px;
        padding-top: 10px;

        .modal-header {
          padding: 20px 15px;
          color: $surface80;
          font-size: 16px;
          font-weight: normal;
          border-bottom: solid 1px $steeper-border;
        }
        .modal-body {
          font-size: 14px;
          color: $surface50;
          .view-account-table {
            height: 150px;
            overflow-y: auto;
            tr,
            td {
              height: 10px;
            }
          }
          // border-bottom: solid 1px $card-border-color;
          .modal-text {
            color: $surface80;
          }
          .versions-dropdown {
            .versions-dropdown__control {
              background: $onboarding-icons !important;
              border: none;
              outline: none;
              box-shadow: none;
            }
            .versions-dropdown__control--is-focused {
              outline: none;
            }
            .versions-dropdown__single-value {
              color: white;
            }
            .versions-dropdown__indicator {
              padding-left: 0px;
            }
            .versions-dropdown__indicator-separator {
              display: none !important;
            }

            .versions-dropdown__menu {
              background: $midnight-express;
              // width: 9.375rem;
              border: 1px solid $midnight-express-blue;
              .versions-dropdown__menu-list {
                width: 100%;
                background: $midnight-express;
                .versions-dropdown__option {
                  margin-top: 0.125rem;
                  margin-bottom: 0.125rem;
                  &:hover {
                    background: $violent-violet;
                    color: $toggle-button-color;
                  }
                }
                .versions-dropdown__option {
                  background: none;
                }
                .versions-dropdown__option--is-selected {
                  background: $violent-violet;
                  color: $toggle-button-color;
                }
              }
            }
          }
          .modal-message {
            border-top: solid 1px $steeper-border;
          }
          .modal-loading-img {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            text-align: center;
            z-index: 1;
            width: 200px;
          }
        }
        .modal-footer {
          .btn {
            text-transform: uppercase;
          }
          .btn-primary {
            border-radius: 3.3652px;

            @media only screen and (max-width: 992px) {
              font-size: 10px;
            }
          }

          .update-stack-disabled {
            opacity: 0.3;
            cursor: default;
          }

          .separator {
            width: 1px;
            height: 30px;
            background-color: $separator-color;
          }

          .popout-img {
            margin-left: 2px;
            width: 18px;
            height: 18px;
            margin-top: -3px;
          }
        }
      }
    }
  }
}
