@import "../../../variables.scss";
#delete-account {
  .modal {
    display: block;
    background: rgba(0, 0, 0, 0.5);
    .modal-dialog {
      top: 10%;
      .modal-content {
        height: 100% !important;
        background: $card-background-color;

        .modal-header {
          padding: 30px 15px;
          color: $surface80;
          font-size: 18px;
          font-weight: normal;
          border-bottom: solid 1px $card-border-color;
        }
        .modal-body {
          color: $surface50;
          font-size: 14px;
          border-bottom: solid 1px $card-border-color;
          .modal-text {
            color: $surface80;
          }
          .modal-loading-img {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            text-align: center;
            z-index: 1;
            width: 200px;
          }
        }
        .modal-footer {
          .btn {
            text-transform: uppercase;
          }
          .btn-primary {
            @media only screen and (max-width: 992px) {
              font-size: 10px;
            }
            &:active {
              background: $sub-heading-color;
              border-color: $sub-heading-color;
            }
            &:disabled {
              background: $sub-heading-color;
              border-color: $sub-heading-color;
              opacity: 0.3;
            }
          }

          .separator {
            width: 1px;
            height: 30px;
            background-color: $separator-color;
          }

          .popout-img {
            margin-left: 2px;
            width: 18px;
            height: 18px;
            margin-top: -3px;
          }
        }
      }
    }
  }
}
