@import "../../../variables.scss";

.integration-cards {
  .card-item {
    :not(:last-child) {
      padding-right: 0;
    }
  }
  .card {
    .card-body {
      img {
        height: 50px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
      }
    }
  }
}

.wiz-background {
  background: $wiz-blue !important;
}
