@import "../../../../variables.scss";
#sensitive-data {
  height: 280px;
  overflow: auto;
  tbody {
    width: 100%;
    overflow-y: auto;
  }

  tbody {
    tr {
      background: none;
      margin-bottom: 4px;
      cursor: unset;

      td {
        height: 25px;
        padding-left: 0px;
        color: $table-cell-text-color-v1;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        border-bottom: 1px solid $border-color-v1;
        .icon {
          margin-top: -3px;
          margin-left: 5px;
        }
      }
      .sensitivity-count {
        font-weight: 700;
        font-size: 16px;
        color: $white-color;
        text-align: end;
      }
    }
  }
}
