@import "../../../variables.scss";

.data-inventroy,
.training-data,
.modal-inventory {
  background: $midnight-express-1;
  margin-bottom: 1.5em;
  .sensitive-data-container {
    min-height: 450px;
    background: $blackcurrant;
    padding: 1em 1.5em;
    h6 {
      font-weight: bold;
      color: $secondary-btn-hover;
    }
    .table-header {
      text-align: center;
    }
    .datastores-row {
      .tag {
        color: $white-color;
        font-weight: 300;
        font-size: 14px;
      }
      .count {
        color: $white-color;
        font-weight: bold;
        font-size: 14px;
      }
      .datastores {
        color: $white-color;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
      }
    }
  }

  .llm-sensitive-data-container {
    min-height: 335px;
    background: $blackcurrant;
    padding: 1em 1.5em;
    h6 {
      font-weight: bold;
      color: $secondary-btn-hover;
    }
    .table-header {
      text-align: center;
    }
    .datastores-row {
      .tag {
        color: $white-color;
        font-weight: 300;
      }
      .count {
        color: $white-color;
        font-weight: bold;
        font-size: 16px;
      }
      .datastores {
        color: $white-color;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
      }
    }
  }
  .overview-data-container {
    background: $blackcurrant;
    padding: 1em 1.5em;
    h6 {
      font-weight: bold;
      color: $secondary-btn-hover;
    }
    .table-header {
      text-align: center;
    }
    .sensitive-table {
      img {
        margin: 0px 10px;
      }
      .num {
        margin: 0px 10px;
        font-weight: bold;
        font-size: 18px;
        color: $white-color;
      }
      .llm-count {
        text-align: center;
      }
      .title {
        margin: 0px 10px;
        font-size: 12px;
      }
    }
  }
  .llm-data-container {
    background: $blackcurrant;
    padding: 1em 1.5em;
    height: 260px;
    overflow: auto;
    h6 {
      font-weight: bold;
      color: $secondary-btn-hover;
    }
    .table-header {
      text-align: center;
    }
    .sensitive-table {
      img {
        margin: 0px 10px;
      }
      .num {
        margin: 0px 10px;
        font-weight: bold;
        font-size: 18px;
        color: $white-color;
      }
      .llm-count {
        text-align: center;
      }
      .title {
        margin: 0px 10px;
        font-size: 12px;
      }
    }
  }
  .row {
    .col {
      margin: 2em;
    }
  }
  .table-main {
    padding: 0.5em;
    min-height: auto;
  }
  .inventroy-title-container {
    h6 {
      margin-top: 1em;
      margin-left: 1.5em;
    }
    display: flex;
    justify-content: space-between;
    icon {
      cursor: pointer;
    }
  }
  .body-close {
    height: 0px !important;
    transition: 0.1;
    display: none;
  }
  .body {
    margin: 1rem;
    padding: 1rem;
    .view-all {
      color: $dark-blue;
      font-weight: 600;
      font-size: 15px;
      margin-top: 0.5em;
      cursor: pointer;
    }
    .distribution-labels {
      display: flex;
      justify-content: center;
      margin-top: 3rem;
      margin-left: 4em;
      label {
        font-size: 12px;
        color: $manatee-shade1;
        margin-left: 10px;
        font-weight: 500;
        text-transform: capitalize;
      }
      div {
        width: 90%;
        min-width: 10%;
        height: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        margin-right: -10px;
        // z-index: 10;
      }
    }
    .distribution-size {
      margin-left: 4em;
      display: flex;
      justify-content: center;
      .size-title {
        margin-left: -0.5em;
      }
      div {
        width: 90%;
        min-width: 10%;
        height: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        margin-right: -10px;
        // z-index: 10;
      }
    }
    .distribution-graph {
      margin-left: 2em;
      width: 90%;
      display: flex;
      justify-content: center;
      div {
        // width: 100%;
        // min-width: 10%;
        height: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        margin-right: -10px;
        // z-index: 10;
      }
      div:nth-child(1) {
        z-index: 10;
        width: 30%;
      }
      div:nth-child(2) {
        z-index: 9;
        width: 40%;
      }
      div:nth-child(3) {
        z-index: 8;
        width: 10%;
      }
      div:nth-child(4) {
        width: 20%;
      }
    }
    h1 {
      color: linear-gradient(
        102.84deg,
        #ac59ff 24.73%,
        #8811ff 37.36%,
        #4e6bff 85.15%,
        #062dff 94.49%,
        #001bae 101.08%
      );
      background: linear-gradient(
        102.84deg,
        #ac59ff 24.73%,
        #8811ff 37.36%,
        #4e6bff 85.15%,
        #062dff 94.49%,
        #001bae 101.08%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    h6 {
      color: $storm-grey;
      font-size: 16px;
    }
    .services {
      .total {
        color: $white-color;
        font-size: 40px;
        font-weight: 700;
      }
      .icon {
        width: 20px;
        margin: 0px;
        margin-top: -3px;
        margin-right: 10px;
      }
      .number {
        color: $white-color;
        margin-right: 10px;
        font-weight: 600;
        font-size: 18px;
      }
      .label {
        color: $manatee-shade1;
        font-size: 12px;
        margin-top: -10px;
      }
    }
    .services:nth-child(1) {
      border-right: 2px solid $storm-grey;
      margin-top: -10px;
    }
  }
}
