@import "../../variables.scss";

.modal-open .modal {
  background: rgba(0, 0, 0, 0.5);
}

.new-modal {
  .modal-lg {
    max-width: 900px !important;
    margin-top: 20px;
  }

  .modal-md-2 {
    max-width: 700px !important;
    margin-top: 20px;
    margin-top: 7%;
  }

  .modal-md {
    margin-top: 100px;
  }

  .active-directory {
    background: #d3d3d3;
    .modal-header {
      h5 {
        color: #6c83bb;
        font-size: 15px;
      }
      background: #d3d3d3;
      border-bottom: 1px solid #b3aeae;
    }

    .modal-footer {
      border-top: 1px solid #b3aeae !important;
    }
  }

  .modal-content {
    height: 60%;

    .sub-title {
      font-family: $manrope-regular-font;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $subtext-color;
    }

    .form-horizontal {
      .form-group {
        div {
          .directory-input {
            width: 50%;
          }
          .btn-light {
            color: #919191;
            border: 1px solid #919191;
          }
        }
      }
    }

    .email-chip {
      font-family: $manrope-regular-font;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $sub-heading-color;
      border-radius: 10px;
      padding: 5px;
      border: 1px solid $sub-heading-color;

      img {
        width: 19px;
        height: 19px;
        opacity: 0.8;
        cursor: pointer;
      }
    }

    .chapter-label {
      font-family: $manrope-regular-font;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $subtext-color;
    }
  }
  .count-circle {
    width: 40px;
    height: 40px;
    background-color: #c83a39;
    border-radius: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    color: $white-color;
    font-weight: 600;
  }
  .modal-header {
    display: initial;
    background-color: #ededed;
    border-bottom-color: $card-border-color;
    font-family: $manrope-regular-font;
  }

  .bullet-point {
    background: #6c83bb;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #fff;
    padding: 0px 5px;
  }

  .modal-body {
    border-bottom: 1px solid;
    border-bottom-color: $card-border-color;
    .row {
      .col {
        label {
          font-size: 12px;
        }
      }
    }
    .modal-subtext {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $subtext-color;
      line-height: 16px;
      display: flex;
      align-items: center;
    }
  }

  .chapter-content {
    .calender {
      display: flex;
      margin-left: 9em;
      margin-top: 10px;
      .day {
        border-top: 1px solid #bbbbbb;
        border-bottom: 1px solid #bbbbbb;
        border-right: 1px solid #bbbbbb;
        padding: 2px;
        width: 80px;
        text-align: center;
        color: #6c83bb;
        background: #fff;
        // cursor: pointer;
      }
      .day-active {
        color: #fff;
        background: #6c83bb;
      }
    }
    .row {
      .col {
        select {
          width: 100px;
          font-size: 12px;
          height: 30px;
          border: 1px solid #d3d3d3;
          background: #fff;
        }
        input {
          background: #ededed;
          border: 1px solid #d3d3d3;
          height: 30px;
          width: 100px;
        }
      }
    }
  }

  .ticket-list {
    div {
      margin-left: 20px;
      h6 {
        color: $grey-shade;
        font-size: 13px;
      }
      p {
        color: $grey-shade;
        font-size: 10px;
      }
    }
  }
  .modal-text {
    color: $surface80;
  }
  select {
    width: 100%;
  }
  textarea {
    width: 100%;
    height: 90px;
  }
  .modal-input-style {
    border-width: 0px;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 35px;
    background: inherit;
    background-color: $input-color;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: $card-border-color;
    border-radius: 0px;
    font-weight: 500;

    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .modal-input {
    border-width: 0px;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 35px;
    background: inherit;
    background-color: $input-color;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: $card-border-color;
    border-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: $surface80;
    font-weight: 500;
  }
  .modal-textarea {
    border-width: 0px;
    left: 0px;
    top: 0px;
    width: 100%;
    background: inherit;
    background-color: $input-color;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: $card-border-color;
    border-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-weight: 500;

    color: $surface80;
  }
  .modal-button {
    border-width: 0px;
    // position: absolute;
    left: 0px;
    top: 0px;
    // width: 98px;
    height: 35px;
    background: inherit;
    border: none;
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: $white-color;
    background-color: $sub-heading-color;
    width: 200px;
  }
  .modal-button-cancel {
    border-width: 0px;
    // position: absolute;
    left: 0px;
    top: 0px;
    // width: 98px;
    height: 35px;
    background: inherit;
    background-color: $surface80;
    border: none;
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: $white-color;
  }
}

.modal-footer {
  .row {
    width: 100%;
  }
}
