@import "../../../variables.scss";
#add-policy-modal {
  font-family: $manrope-regular-font;
  .modal {
    display: block;
    background: rgba(0, 0, 0, 0.5);
    .modal-dialog {
      top: 0px !important;
      max-width: 750px;
      .modal-content {
        height: 100% !important;
        background: $card-background-color;

        .modal-header {
          padding: 15px 15px;
          .header-title {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $sub-heading-color;
          }

          .delete-icon {
            position: absolute;
            right: 0;
            cursor: pointer;
          }
        }
        .modal-body {
          .accounts-dropdown__single-value {
            color: unset;
          }
          .label {
            margin-bottom: 0px;
          }
          .modal-loading-img {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            margin-top: 50px;
            text-align: center;
            z-index: 1;
            width: 200px;
          }
          .my-custom-react-select__menu {
            z-index: 2;
          }
          .risk-accept-toggle-left {
            margin-right: 44px;
          }
          border-bottom: 1px solid;
          border-bottom-color: $card-border-color;
          max-height: 550px;
          overflow: scroll;
          .modal-subtitle {
            color: #374948;
            font-size: 14px;
            font-weight: 500;
          }
          .form-control {
            line-height: 45px;
            background: #e9edf4;
            border: 0px solid #d7dbda;
            font-size: 14px;
            color: #777d74;
          }
          .modal-textarea {
            border-width: 0px;
            left: 0px;
            top: 0px;
            width: 100%;
            background: inherit;
            background-color: $input-color;
            box-sizing: border-box;

            border-radius: 2px !important;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
          }
        }
        .modal-footer {
          display: block;
          .modal-button-cancel {
            background-color: $card-heading-color;
            color: $white-color;
          }
        }
      }
    }
  }
}

.risk-accept-toggle {
  vertical-align: middle;
  .react-toggle-track {
    background: $toggle-track;
    height: 22px;
  }
  .react-toggle-thumb {
    background-color: $toggle-button-color;
    border: none;
    box-shadow: none;
    top: 0px !important;
    right: 10px;
  }
}
.risk-accept-toggle.react-toggle--checked {
  .react-toggle-track {
    background: #19ab27 !important;
  }
  .react-toggle-thumb {
    background-color: $toggle-button-color;
    border: none;
    box-shadow: none;
  }
}

.workday-subheading-value {
  .accounts-dropdown__single-value {
    color: #fff !important;
  }
}

.chip-container .chip {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $dark-blue;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-radius: 3px;
  padding: 5px 10px;
  border: 1px solid $chip-background;

  .close-icon {
    width: 16px;
    height: 16px;
    float: right;
    object-fit: contain;
    cursor: pointer;
    border-radius: 50%;
  }
}
