@import "../../../variables.scss";

.first-account-stats {
  .first-account-text {
    font-size: 12px;
    color: $manatee-shade1;
    width: 30% !important;
    padding: 20px;
  }
  .first-account-card {
    background: $card-background-color;
    height: calc(100% - 1.5rem);

    min-height: 12rem;
    margin-bottom: 1.5rem;
    border-top-width: 2px;
    border-top-style: solid;
    border-radius: 6.9128px;
    border-image: linear-gradient(
        102.8deg,
        #c241af -2.26%,
        #002bff 77.68%,
        #4377f7 117.73%
      )
      3;

    .card-contents {
      padding: 1.25rem;
      hr {
        opacity: 0.15;
      }
      .data-card-header {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.188rem;
        color: $manatee-shade1;
      }
      .data-card-value {
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.188rem;
        color: $white-color;
      }
    }
  }

  .first-account-title-text {
    font-weight: 400;
    color: $surface70;
    font-size: 14px;
  }
}
