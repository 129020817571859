@import "../../variables.scss";

.tickets {
  margin: 0px 20px;
  .table {
    border: none;
    thead {
      tr {
        background: none !important;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.11);
        border: none !important;
        color: $subtext-color;
        font-weight: 500;
        th:nth-child(1) {
          width: 50px;
        }
        th:nth-child(2) {
          width: 110px;
        }
        th:nth-child(3) {
          width: 200px;
        }

        th:nth-child(4) {
          width: 150px;
        }

        th:nth-child(5) {
          width: 150px;
        }
        th:nth-child(7) {
          width: 200px;
        }
      }
    }

    tbody {
      tr {
        tr:nth-child(even) {
          background: $ticket-tbody !important;
        }

        tr:nth-child(odd) {
          background: $card-border-color !important;
        }

        margin-bottom: 10px !important;

        td {
          color: $subtext-color !important;

          i {
            font-size: 20px;
            margin: 0px 5px;
            color: $surface80;
          }
        }
        td:nth-child(2) {
          color: $surface80 !important;
          font-weight: 600 !important;
        }

        td:nth-child(5) {
          font-weight: 600 !important;
        }
      }
    }
  }
}

.pagination {
  &-btn {
    color: $white-color;
    border: none;
    color: $white-color;
    padding: 5px 7px;
    font-size: 14px;
    margin: 5px;
    cursor: pointer;
    border-radius: 50%;

    &-disable {
      @extend .pagination-btn;
      cursor: not-allowed;
    }
    &-icon {
      margin-top: 10px;
    }
  }
}
