@import "../../../variables.scss";
#manage-tags-modal {
  font-family: $manrope-regular-font;
  .modal {
    display: block;
    background: rgba(0, 0, 0, 0.5);
    .modal-dialog {
      max-width: 500px;
      .modal-content {
        height: 100% !important;
        background: $card-background-color;
        .modal-loading-img {
          position: absolute;
          margin: auto;
          left: 0;
          right: 0;
          text-align: center;
          z-index: 1;
          width: 200px;
        }
        .modal-header {
          padding: 15px 15px;
          .header-title {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $surface80;
          }

          .delete-icon {
            position: absolute;
            right: 0;
            cursor: pointer;
          }
        }
        .modal-body {
          border-bottom: 1px solid;
          border-bottom-color: $card-border-color;

          .chip-container .chip {
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $surface80;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            padding: 3px 3px 3px 5px;
            border: 1px solid $surface80;

            .close-icon {
              width: 16px;
              height: 16px;
              float: right;
              object-fit: contain;
              cursor: pointer;
              border-radius: 50%;
            }
          }
        }
        .modal-footer {
          .modal-button-cancel {
            background-color: $surface80;
            color: $white-color;
          }
        }
      }
    }
  }
}
