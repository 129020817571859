@import "../../../../variables.scss";

.dashboard-card {
  height: 40px;
  .dashboard-card-title {
    color: $white-color;
    opacity: 0.9;
    font-size: 15px;
    font-weight: 600;
    line-height: 21px;
  }
  .dashboard-card-count {
    color: $white-color;
    font-size: 34px;
    font-weight: 600;
  }
  .dashboard-card-count-chip {
    background-color: $dashboard-subcard-color;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    color: $text-highlight-color;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    padding-top: 7px;
  }
}
