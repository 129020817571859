@import "../../../variables.scss";
.custom-node-popup-content {
  min-width: 260px;
  border: 1px solid #7f7f7f;
}

.title-popup-body {
  border-radius: 4px;
}
.linear-graph {
  .node {
    section {
      align-items: center;
      height: 115px !important;
    }
  }
}
.node {
  section {
    display: flex;
    justify-content: center;
  }
}
.tooltip-close-icon {
  position: absolute;
  right: 11px;
  top: 14px;
  width: 17px;
  cursor: pointer;
}
.training-data-tooltip {
  overflow: scroll;
  position: absolute;
  right: -5px;
  z-index: 899;
  top: -20px;
  background-color: $tooltip-background-color;
  width: 260px;
  height: auto;
  font-size: 12px;
  color: $world-map-tooltip-color;
  border-radius: 0px 10px 10px 0px;
  &-graph {
    height: 468px;
    max-height: 468px;
  }
  &-linear {
    height: 425px;
    max-height: 425px;
  }

  font-weight: 500;
  &-top {
    // margin: 5px;
    // padding-left: 5px;
  }
  &-bottom {
    height: 303px;
    overflow-y: auto;
  }
  &-type {
    border-top: 1px solid $border-color-v1-3;
    padding: 10px 0 10px;
    margin-left: 0px;
    margin-right: 0px;
  }
  &-content {
    font-weight: 700;
    padding-left: 2px;
    margin: 0px;
  }
  &-header {
    font-size: 15px;
    font-weight: 700;
    color: $white-color;
  }
  &-title {
    color: $toggle-button-color;
    font-weight: 600;
    font-size: 14px;
    background-color: rgba(174, 105, 244, 0.1);
    padding-left: 10px;
    height: 25px;
  }
  &-subtitle {
    padding-left: 10px;
    color: $border-color-v1-5;
    font-weight: 400;
  }
  &-description {
    color: $white-color;
    word-break: break-all;
    font-weight: 600;
  }
  &-more {
    color: $link-v1;
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
    span {
      border-bottom: 1px solid;
    }
  }
}
