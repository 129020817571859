@import '../../variables.scss';


#cd-sidebar {
  background: 'transparent';
  position: fixed;
  z-index: 999;
  border-right: 0.063rem solid rgba(255, 255, 255, 0.05);
  height: 100%;
  transition: all .4s ease-in-out;
  font-family: Inter;

  .cd-logo {
    padding: 1.25rem 0px;
    .visible {
      width:64px !important;
    }
    .hidden {
      width:0px !important;
    }
    .daxa-logo-title{
      overflow: hidden;
      transition: all .4s ease-in-out;
    }
  }

  .sidebar-menu {
    padding: 10px 10px;
    width:45px;
    &:hover {
      background: $violent-violet;
      border-radius: 4px;
      svg {
        fill: $violent-violet;
        path {
          stroke: $toggle-button-color;
          stroke-width: 1.63371;
          stroke-linecap: round;
          stroke-linejoin: round;
        }
      }
    }
  }
  .arrow-icon {
    transition: all .4s ease-in-out;
    width: fit-content;
  }
  .right-aligned{
    left: 0;
    right: 0;
    margin-right: 16px;
    margin-left: auto;
  }
  .centered{
    left:0;
    right:0;
    margin: auto;
  }
  .sidebar-menu-extended {
    align-items: center;
    display: flex;
    padding: 10px 10px;
    width:100%;
    &:hover {
      background: $violent-violet;
      border-radius: 4px;
      svg {
        fill: $violent-violet;
        path {
          stroke: $toggle-button-color;
          stroke-width: 1.63371;
          stroke-linecap: round;
          stroke-linejoin: round;
        }
      }
    }
  }

  .sidebar-menu-selected {
    padding: 10px 10px;
    width:45px;
    background: $violent-violet;
    border-radius: 4px;
    svg {
      fill: $violent-violet;
      path {
        stroke: $toggle-button-color;
        stroke-width: 1.63371;
        stroke-linecap: round;
        stroke-linejoin: round;
      }
    }
  }

  .sidebar-menu-selected-extended {
    align-items: center;
    display: flex;
    padding: 10px 10px;
    background: $violent-violet;
    border-radius: 4px;
    width:100%;
    svg {
      fill: $violent-violet;
      path {
        stroke: $toggle-button-color;
        stroke-width: 1.63371;
        stroke-linecap: round;
        stroke-linejoin: round;
      }
    }
  }
}
.expanded-sidebar {
  max-width: 10.375rem;
  width: 100%;
}
.collapsed-sidebar {
  max-width: 4.875rem;
  width:100%
}
.sidebar-menu-title {
  color: $grey-shade-4;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  overflow: hidden;
}
.sidebar-menuItem{
  transition: width .4s ease-in-out;
}
.sidebar-menu-container{
  display:grid;
  gap:8px;
  grid-template-columns: minmax(0, 1fr);
}
