@import "../../../../variables.scss";
#cloud-env-issues {
  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    color: $white-color;
    &-all {
      font-size: 14px;
      color: $link-v1;
      cursor: pointer;
    }
  }
  .table-heading {
    height: 35px;
    width: 100%;
    // background-color: $table-cell-text-color-v1;
    border-radius: 4px;
    &-title {
      font-size: 13px;
      font-weight: 600;
    }
  }
  .issue-count {
    color: $white-color;
    font-weight: 600;
  }
  .circle {
    &-vulnerable-ec2-compute {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $cloud-env-class-color-1;
    }
    &-vulnerable-k8s-cluster {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $cloud-env-class-color-2;
    }
    &-serverless-misconfig {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $cloud-env-class-color-3;
    }
  }
  .cloud-env-title {
    cursor: pointer;
  }
  thead {
    tr {
      background-color: $table-header !important;
      cursor: unset;

      th:nth-child(1) {
        border-radius: 5px 0 0 5px !important;
      }
      th:nth-child(2) {
        padding-left: 0px;
      }
      th:nth-child(3) {
        padding-right: 4px;
        padding-left: 5px;
        border-radius: 0 5px 5px 0 !important;
        text-align: end;
      }
      th {
        border-radius: 0px;
        background-color: $table-background-color-v1 !important;
      }

      height: 35px;
      width: 100%;
      border-radius: 4px;
      &-title {
        color: #2d2d2d;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  tbody {
    tr {
      background: none;
      margin-bottom: 4px;
      cursor: unset;
      td {
        height: 25px;
        padding: 0px;
        color: $table-cell-text-color-v1;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        .icon {
          margin-top: -3px;
          margin-left: 5px;
        }
      }
      td:last-child {
        width: fit-content;
        padding: 0px;
        padding-right: 15px !important;
      }
      .sensitivity-count {
        font-weight: 700;
        font-size: 16px;
        color: $white-color;
        text-align: end;
        padding: 0px;
        padding-right: 4px !important;
      }
    }
  }
}
