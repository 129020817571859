@import "../../../../variables.scss";

#card-layout {
  height: 100%;
  background-color: $card-background-color-v1;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid $border-color-v1-2;

  .sub-title-right {
    position: absolute;
    right: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    top: 25px;
    color: $storm-grey;
  }

  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    .sub-title {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: $ghost-grey-shade;
      background: $violent-violet;
      border: 1px solid $christalle;
      border-radius: 4px;
      align-items: center;
      padding: 7px 10px;
      margin-top: -10px;
      display: flex;
      .breach-cost {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: $white-color;
      }
    }
  }
}
