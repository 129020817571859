@import "../../../../variables.scss";
#inventory {
  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    color: $white-color;
  }
  .divider {
    height: 230px;
    border: 1px solid;
    background-color: $dark-blue;
    opacity: 0.1;
    align-items: center;
  }
  .tooltip-close-icon {
    position: absolute;
    right: 11px;
    top: 14px;
    width: 11px;
    cursor: pointer;
  }

  .world-map-tooltip {
    position: absolute;
    right: -5px;
    z-index: 899;
    top: -41px;
    background-color: $tooltip-background-color;
    width: 180px;
    height: auto;
    font-size: 12px;
    color: $world-map-tooltip-color;
    border-radius: 0px 10px 10px 0px;
    height: 352px;
    max-height: 352px;
    font-weight: 500;
    &-top {
      margin: 5px;
      padding-left: 5px;
    }
    &-bottom {
      height: 303px;
      overflow-y: auto;
    }
    &-type {
      border-top: 1px solid $border-color-v1-3;
      padding: 10px 0 10px;
      margin-left: 0px;
      margin-right: 0px;
    }
    &-content {
      font-weight: 700;
      padding-left: 2px;
      margin: 0px;
    }
    &-header {
      font-size: 15px;
      font-weight: 700;
      color: $white-color;
    }
    &-title {
      color: $toggle-button-color;
      font-weight: 600;
      font-size: 14px;
      background-color: rgba(174, 105, 244, 0.1);
      padding-left: 10px;
      height: 25px;
    }
    &-subtitle {
      padding-left: 10px;
      color: $border-color-v1-5;
      font-weight: 400;
    }
    &-description {
      color: $white-color;
      font-weight: 600;
    }
    &-more {
      color: $link-v1;
      font-weight: 600;
      cursor: pointer;
      font-size: 14px;
      margin-left: 10px;
      span {
        border-bottom: 1px solid;
      }
    }
  }
}
