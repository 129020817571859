@import "../../../variables.scss";
.risk-card-container {
  .card {
    height: 100%;
    border-image: linear-gradient(
        102.8deg,
        #c241af -2.26%,
        #002bff 77.68%,
        #4377f7 117.73%
      )
      3;
    border-top-style: solid;
    border-top-width: 2px;
  }
  .main-card {
    min-width: 160px;
  }
  .risk-card-title {
    font-family: $manrope-regular-font;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #797979;
    border-bottom: 0.559702px solid #333647;
    padding: 10px 0 16px;
  }
  .vertical-line {
    margin-left: 15px;
    border-left: 1px solid #b7b7b7;
    height: 105px;
    background-color: #e2e2e2;
  }

  .risk-card-score {
    font-family: $manrope-regular-font;
    font-size: 32px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 10px 0 5px;
  }

  .score-direction {
    float: right;
    font-size: 18px;
  }
  .steps-count {
    width: 23px;
    height: 21px;
    margin-right: 8px;
    line-height: 23px;
    border-radius: 50%;
    text-align: center;
    object-fit: contain;
    &-disable {
      @extend .steps-count;
      opacity: 0.6;
    }
    &-enable {
      @extend .steps-count;
      opacity: 1;
    }
  }
  .selected-card {
    background-color: #9738f6;
    &-text {
      color: $white-color !important;
    }
  }

  .card-number-info {
    background: $sub-heading-color;
  }
  .card-number-warning {
    background: #dfae27;
  }
  .card-number-danger {
    background: $error-color;
  }

  .card-icon {
    width: 16px;
    background: $sub-heading-color;
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 50%;
  }
  .card-loading-icon {
    width: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 50%;
  }
  .card {
    width: 100%;
    &-disable {
      opacity: 0.6;
    }
    &-enable {
      border: solid 1px $grey-color !important;
      background-color: $white-color;
    }
  }
  .discovering-text {
    padding-right: 0px;
    padding-left: 15px;
    border-left: 1px solid #b7b7b7;
    height: 100%;
    @media (max-width: 992px) {
      border: 0;
    }
    img {
      width: 22px;
    }
    div {
      padding-left: 15px;
      p {
        font-family: $manrope-regular-font;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $subtext-color;
        margin-bottom: 0.5rem;
      }
      button {
        background-color: $score-low-color;
      }
    }
  }
}
